<template>
  <div class="container">
    <div class="account">
      <div class="left">
        <div class="info">
          <div class="line2">
            <div>
              我的账号：{{ storeInfo.account_no }}（{{ storeInfo.bal_type }}）
            </div>
            <div class="state" v-if="storeInfo.shop_status">
              状态：{{ storeInfo.shop_status }}
            </div>
          </div>
          <div class="line2">
            <div class="name" v-if="storeInfo.shop_name">
              账户名称：{{ storeInfo.shop_name }}
              <span
                style="font-size: 22px"
                class="iconfont iconxiugai"
                @click="dialogVisible = true"
              ></span>
            </div>
            <div class="phone">保证金：{{ storeInfo.deposit }}</div>
          </div>

          <div class="line2">
            <div class="phone" v-if="storeInfo.cell_phone">
              手机号：{{ storeInfo.cell_phone }}
            </div>
            <div class="mailbox">邮箱：{{ storeInfo.email || "-" }}</div>
          </div>

          <div class="line2">
            <div class="contacts" v-if="storeInfo.contact">
              联系人：{{ storeInfo.contact }}
            </div>
            <div v-if="storeInfo.is_check == 1">
              已同意<span @click="checkRules" class="rules"
                >《保税仓订单规则》</span
              >
            </div>
            <div v-else>
              未同意<span @click="checkRules" class="rules"
                >《保税仓订单规则》</span
              >
            </div>
          </div>
          <div class="line2">
            <div class="phone" v-if="storeInfo.real_name">
              日结姓名：{{ storeInfo.real_name }}
            </div>
            <div class="contacts" v-if="storeInfo.account_number">
              日结账号：{{ storeInfo.account_number }}
            </div>
          </div>
          <div class="line2">
            <el-button type="primary" style="margin-left: 0" @click="openDialog"
              >提现账户绑定</el-button
            >
            <!-- <el-button
              type="primary"
              style="margin-left: 30px"
              @click="withdrawalVisible = true"
              >提现</el-button
            > -->
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title"><i class="el-icon-message-solid"></i></div>
        <div class="box">
          <div
            style="cursor: pointer"
            class="item"
            v-for="item in announcementList"
            @click="getMessageDetail(item)"
            :key="item.msg_id"
          >
            {{ item.msg_title }}
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">总销售金额</div>
        <div class="dfcs">
          <span>{{ accountData.sum_order_sales_volume }}</span>
          <div
            :class="
              accountData.day_yoy_sum_order_sales_volume_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{
                accountData.day_yoy_sum_order_sales_volume_percentage
              }}%</span
            >
            <img
              v-if="accountData.day_yoy_sum_order_sales_volume_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">采购销售总金额</div>
        <div class="dfcs">
          <span>{{ accountData.sum_purchase_price }}</span>
          <div
            :class="
              accountData.day_yoy_sum_purchase_price_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{ accountData.day_yoy_sum_purchase_price_percentage }}%</span
            >
            <img
              v-if="accountData.day_yoy_sum_purchase_price_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">共享销售总金额</div>
        <div class="dfcs">
          <span>{{ accountData.sum_yes_order_sales_volume }}</span>
          <div
            :class="
              accountData.day_yoy_sum_yes_order_sales_volume_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{
                accountData.day_yoy_sum_yes_order_sales_volume_percentage
              }}%</span
            >
            <img
              v-if="
                accountData.day_yoy_sum_yes_order_sales_volume_percentage >= 0
              "
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">已提现</div>
        <div class="dfcs">
          <span>{{ accountData.already_withdrawal }}</span>
          <div
            :class="
              accountData.day_yoy_already_withdrawal_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{ accountData.day_yoy_already_withdrawal_percentage }}%</span
            >
            <img
              v-if="accountData.day_yoy_already_withdrawal_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">可提现</div>
        <div class="dfcs">
          <span>{{ accountData.can_withdrawal }}</span>
          <div
            :class="
              accountData.day_yoy_can_withdrawal_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{ accountData.day_yoy_can_withdrawal_percentage }}%</span
            >
            <img
              v-if="accountData.day_yoy_can_withdrawal_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">待结算</div>
        <div class="dfcs">
          <span>{{ accountData.wait_withdrawal }}</span>
          <div
            :class="
              accountData.day_yoy_wait_withdrawal_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{ accountData.day_yoy_wait_withdrawal_percentage }}%</span
            >
            <img
              v-if="accountData.day_yoy_wait_withdrawal_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <el-tabs type="card" v-model="currentTabName" @tab-click="changTabs">
        <el-tab-pane class="tab-pane" name="first" label="支付宝订单结算">
          <div class="select">
            <div class="input">
              <span>备注：</span>
              <el-input
                v-model="remark"
                placeholder="请输入备注内容"
              ></el-input>
            </div>

            <div class="state">
              <span>结算状态：</span>

              <el-select
                v-model="settlement_status"
                clearable
                placeholder="请选择"
              >
                <el-option label="待结算" value="0"></el-option>
                <el-option label="已结算" value="1"></el-option>
              </el-select>
            </div>
            <div class="state">
              <span>创建时间：</span>
              <el-date-picker
                v-model="create_time"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </div>
          </div>
          <div class="select">
            <div class="state">
              <span>结算时间：</span>
              <el-date-picker
                v-model="settlement_time"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </div>
            <div class="input">
              <span>关键字：</span>
              <el-input
                v-model="keyword"
                placeholder="请输入备注内容"
              ></el-input>
            </div>
            <el-button
              type="primary"
              @click="getOrderLockInventorySettlementList(1)"
              >查询</el-button
            >
            <el-button type="info" @click="orderLockInventorySettlementExport"
              >导出</el-button
            >
            <el-button
              type="info"
              @click="orderLockInventorySettlementDetailExport"
              >结算明细导出</el-button
            >
          </div>
          <div class="table">
            <el-table
              border
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{ textAlign: 'center' }"
              :data="settlementList"
              stripe
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="编号"
                width="60"
              ></el-table-column>
              <el-table-column
                prop="settlement_no"
                label="结算单号"
              ></el-table-column>

              <el-table-column
                prop="settlement_receipt_account"
                label="结算账号"
              ></el-table-column>
              <el-table-column
                prop="settlement_receipt_real_name"
                label="结算姓名"
              ></el-table-column>

              <el-table-column
                prop="settlement_real_price"
                label="结算总金额"
              ></el-table-column>
              <el-table-column
                prop="settlement_status"
                width="130"
                label="结算状态"
              >
                <template slot-scope="scope">
                  <div class="text" v-if="scope.row.settlement_status == 0">
                    待结算
                  </div>
                  <div class="text" v-if="scope.row.settlement_status == 1">
                    已结算
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="daily_amount"
                label="日结金额"
              ></el-table-column>
              <el-table-column
                prop="commission_amount"
                label="日结佣金"
              ></el-table-column>
              <el-table-column prop="remark" label="备注"></el-table-column>
              <el-table-column
                prop="settlement_time"
                label="结算时间"
              ></el-table-column>

              <el-table-column
                prop="create_time"
                label="创建时间"
              ></el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <div class="buttons">
                    <el-button
                      @click="getSettlementDetail(scope.row)"
                      type="text"
                      size="small"
                      >查看详情</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @current-change="curretnSettlementChange"
              @prev-click="curretnSettlementChange"
              @next-click="curretnSettlementChange"
              :current-page="settlementPage"
              :hide-on-single-page="false"
              @size-change="handleSettlementSizeChange"
              layout="total,sizes, prev, pager, next,jumper"
              :total="settlementTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane class="tab-pane" name="second" label="我的佣金">
          <el-form
            :inline="true"
            :model="commissionForm"
            class="demo-form-inline"
          >
            <el-form-item label="订单号：">
              <el-input
                v-model="commissionForm.order_no"
                placeholder="订单号"
              ></el-input>
            </el-form-item>
            <el-form-item label="佣金类型：">
              <el-select
                clearable
                v-model="commissionForm.commission_type"
                placeholder="佣金类型"
              >
                <el-option
                  v-for="(item, i) in commission_types"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="提现状态：">
              <el-select
                clearable
                v-model="commissionForm.drawal_status"
                placeholder="提现状态"
              >
                <el-option
                  v-for="(item, i) in drawal_status"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="佣金生成时间：">
              <el-date-picker
                v-model="commission_time"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="-"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="起始时间"
                end-placeholder="结束时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="background-color: #0078fb"
                @click="getCommissionList(1)"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="background-color: #0078fb"
                @click="shopCommissionExport"
                >导出</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="background-color: #0078fb"
                @click="withdrawalVisible = true"
                >提现</el-button
              >
            </el-form-item>
          </el-form>
          <div class="table" style="margin-top: 0">
            <el-table
              border
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{ textAlign: 'center' }"
              :data="shopAmountList"
              stripe
              @sort-change="sortChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="编号"
                width="60"
              ></el-table-column>
              <el-table-column
                prop="commission_type"
                label="佣金类型"
              ></el-table-column>
              <el-table-column prop="order_no" label="订单号"></el-table-column>
              <el-table-column
                prop="order_time"
                label="订单时间"
              ></el-table-column>
              <el-table-column
                prop="real_price"
                label="订单金额"
              ></el-table-column>
              <el-table-column
                prop="total_amount"
                label="佣金金额"
              ></el-table-column>

              <el-table-column
                prop="drawal_status"
                label="提现状态"
              ></el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="total,sizes, prev, pager, next,jumper"
              @size-change="handleSizeChange"
              @current-change="curretnChange"
              @prev-click="curretnChange"
              :page-size="limit"
              :current-page="page"
              :hide-on-single-page="false"
              @next-click="curretnChange"
              :total="shopAmountTotal"
            ></el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane class="tab-pane" name="fifth" label="提现记录">
          <div class="select">
            <div class="state">
              <span>提现类型：</span>
              <el-select
                v-model="withdrawal_type"
                clearable
                placeholder="请选择"
              >
                <el-option label="佣金提现" value="1"></el-option>
                <el-option label="返点提现" value="2"></el-option>
                <el-option label="保证金提现" value="3"></el-option>
                <el-option label="推广奖励" value="4"></el-option>
                <el-option label="日结提现" value="6"></el-option>
              </el-select>
            </div>
            <div class="state">
              <span>处理状态：</span>
              <el-select
                v-model="withdrawal_status"
                clearable
                placeholder="请选择"
              >
                <el-option label="待确认" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
              </el-select>
            </div>
            <el-button type="primary" @click="getShopWithdrawalList(1)"
              >查询</el-button
            >
            <el-button type="info" @click="shopAmountListExport"
              >导出</el-button
            >
          </div>

          <div class="table">
            <el-table
              border
              :cell-style="{ textAlign: 'center' }"
              :header-cell-style="{ textAlign: 'center' }"
              :data="shopWithdrawalList"
              stripe
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="编号"
                width="60"
              ></el-table-column>
              <el-table-column prop="type" label="提现类型"></el-table-column>
              <el-table-column
                prop="draw_no"
                label="提现流水号"
              ></el-table-column>
              <el-table-column
                prop="draw_amount"
                label="提现金额"
              ></el-table-column>
              <el-table-column
                prop="draw_date"
                label="提现时间"
              ></el-table-column>
              <el-table-column
                prop="account_no"
                label="收款账号"
              ></el-table-column>
              <el-table-column
                prop="maybe_payment_time"
                label="预计打款时间"
              ></el-table-column>
              <el-table-column
                prop="real_payment_time"
                label="实际打款时间"
              ></el-table-column>
              <el-table-column
                prop="transaction_no"
                label="交易流水号"
              ></el-table-column>
              <el-table-column
                prop="deal_status"
                width="130"
                label="处理状态"
              ></el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @current-change="curretnChange"
              @prev-click="curretnChange"
              @next-click="curretnChange"
              :current-page="page"
              :page-size="limit"
              :hide-on-single-page="false"
              @size-change="handleSizeChange"
              layout="total,sizes, prev, pager, next,jumper"
              :total="shopWithdrawalTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <ModifyInformation
      :dialogVisible="dialogVisible"
      @changeDialogVisible="setVisible"
    ></ModifyInformation>
    <ModifyPassword
      :passwordVisible="passwordVisible"
      @changePasswordVisible="setPasswordVisible"
    ></ModifyPassword>
    <!-- 订单详情页窗口 -->
    <el-dialog
      title="结算详情"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="detailVisible"
      width="80%"
    >
      <div>
        <div class="select" style="margin-bottom: 10px">
          <el-button type="primary" @click="settlementDetailExport"
            >导出</el-button
          >
        </div>
        <el-table
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          :data="settlementDetailList"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="id" width="80" label="ID"></el-table-column>
          <el-table-column
            prop="settlement_no"
            label="结算单号"
          ></el-table-column>
          <el-table-column prop="order_no" label="订单号"></el-table-column>

          <el-table-column
            prop="real_price"
            width="140"
            label="真实价格"
          ></el-table-column>
          <el-table-column
            prop="shipping_fee"
            width="140"
            label="运费"
          ></el-table-column>
          <el-table-column prop="order_time" label="下单时间"></el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
          <!-- <el-table-column prop="update_time" label="更新时间"></el-table-column> -->
        </el-table>
      </div>
    </el-dialog>

    <!-- 提现账户绑定窗口 -->
    <el-dialog
      title="账户绑定"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="withdrawalBindVisible"
      width="40%"
    >
      <el-form ref="form" :model="bindAmountForm" label-width="120px">
        <el-form-item label="账号类型">
          <el-radio-group v-model="bindAmountForm.type" @input="radioInput">
            <el-radio :label="1">支付宝</el-radio>
            <!-- <el-radio :label="2">银行卡</el-radio> -->
          </el-radio-group>
        </el-form-item>

        <el-form-item label="提现账号">
          <el-input v-model="bindAmountForm.account_no"></el-input>
        </el-form-item>
        <el-form-item label="提现账户名">
          <el-input v-model="bindAmountForm.account_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="withdrawalAccountBind"
            >立即绑定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 提现窗口 -->
    <el-dialog
      title="提现"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="withdrawalVisible"
      width="40%"
    >
      <el-form ref="form" :model="amountForm" label-width="120px">
        <el-form-item label="提现类型">
          <el-radio-group v-model="amountForm.draw_type" @input="typeInput">
            <el-radio label="CE">佣金</el-radio>
            <el-radio label="FD">返点</el-radio>
            <el-radio label="BZJ">保证金</el-radio>
            <el-radio label="TGJ">推广奖励</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现金额">
          <el-input disabled v-model="amountForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="提现账号">
          <el-radio-group v-model="amountForm.account_id">
            <el-radio
              v-for="item in withdrawalAccountList"
              :key="item.account_id"
              :label="item.account_id"
              >{{ item.type_name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="withdrawalAdd">立即提现</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看消息 -->
    <el-dialog
      :close-on-press-escape="false"
      :visible.sync="dialogMessageVisible"
      :title="messageDetail.msg_title"
      width="50%"
    >
      <div class="content">
        <div class="message" v-html="messageDetail.msg_content"></div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store/index.js";
import { formatDateArray, urlEncode } from "../utils/util.js";
import ModifyInformation from "../components/ModifyInformation";
import ModifyPassword from "../components/ModifyPassword";
import { BASE_URL2 } from "../request/request.config";
export default {
  name: "account",
  components: {
    ModifyInformation,
    ModifyPassword,
  },
  data() {
    return {
      dialogVisible: false,
      dialogMessageVisible: false,
      passwordDisplay: false,
      withdrawalBindVisible: false,
      withdrawalVisible: false,
      currentTab: "0",
      commissionForm: {
        commission_type: "",
        drawal_status: "",
        order_no: "",
      },
      commission_time: [],
      currentTabName: "first",
      create_time: [],
      settlement_time: [],
      settlementList: [],
      settlement_status: "",
      withdrawal_status: "",
      withdrawal_type: "",
      input: "",
      page: 1,
      diffAmountTotal: 0,
      remark: "",
      keyword: "",
      detailPage: 1,
      detailLimit: 10,
      settlementPage: 1,
      settlementLimit: 10,
      limit: 10,
      total: 0,
      rebate_type: "1",
      detailTotal: 40,
      settlementTotal: 40,

      statusValue: "",
      storeInfo: {},
      monthValue: "",
      detail_order_by: "",
      order_by: "",
      tempSettlementNo: "",
      stateValue: "",
      settlementDetailList: [],
      bindAmountForm: {
        account_name: "",
        account_no: "",
        type: 1,
      },
      amountForm: {
        draw_type: "",
        amount: "",
        account_id: "",
      },
      withdrawalAccountList: [],
      shopWithdrawalList: [],
      shopWithdrawalTotal: 0,
      shopAmountList: [], // 销售金额明细列表
      passwordVisible: false,
      shopAmountTotal: 0,
      detailVisible: false,

      announcementList: [],
      accountData: {},
      drawal_status: [],
      commission_types: [],
      messageDetail: {},
    };
  },
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
      },
      set() {},
    },
  },
  created() {
    if (this.$route.params.index) {
      this.currentTabName = this.$route.params.index;
    }
    this.getShopInfo();
    this.getCommissionStatusTypes();
    this.getOrderLockInventorySettlementList();
    this.getWithdrawalAccountList();

    this.getShopWithdrawalList();
    this.getAccountIndexData();
    this.getAnnouncementList();
  },
  methods: {
    shopCommissionExport() {
      let token = window.localStorage.getItem("token");
      let shop_id = this.shopInfo.shop_id;
      let start_date = "";
      let end_date = "";
      if (
        Object.prototype.toString.call(this.commission_time) ===
          "[object Array]" &&
        this.commission_time[0]
      ) {
        start_date = this.commission_time[0];
        end_date = this.commission_time[1];
      }
      try {
        let params = {
          ...this.commissionForm,
          start_date,
          end_date,
        };
        params.token = token;
        params.shop_id = shop_id;
        let data = urlEncode(params);
        window.location.href = BASE_URL2 + "/merapi/commission/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    getCommissionList(pageNum) {
      this.page = pageNum || this.page;
      let shop_id = this.shopInfo.shop_id;
      let start_date = "";
      let end_date = "";
      if (
        Object.prototype.toString.call(this.commission_time) ===
          "[object Array]" &&
        this.commission_time[0]
      ) {
        start_date = this.commission_time[0];
        end_date = this.commission_time[1];
      }
      // 店铺佣金列表
      let params = {
        ...this.commissionForm,
        page: this.page,
        shop_id,
        limit: this.limit,
        start_date,
        end_date,
      };
      this.$http2
        .get("/merapi/commission/list", { params })
        .then((res) => {
          this.shopAmountList = res.data.data.rows;
          this.shopAmountTotal = res.data.data.total;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getCommissionStatusTypes() {
      this.$http2
        .get("/merapi/commission/status_types")
        .then((res) => {
          let { commission_types, drawal_status } = res.data.data;
          this.commission_types = commission_types;
          this.drawal_status = drawal_status;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getAnnouncementList() {
      // 获取消息列表

      var page = this.page;
      var limit = this.limit;
      this.$http2
        .post("/merapi/message/list", {
          page,
          limit,
          view_status: "",
        })
        .then((res) => {
          this.announcementList = res.data.data.list;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    getAccountIndexData() {
      this.$http2.get("/merapi/shop/getAccountStatistics").then((res) => {
        this.accountData = res.data.data;
      });
    },
    shopAmountListExport() {
      // 导出

      let currentTabName = this.currentTabName;
      let token = window.localStorage.getItem("token");

      let that = this;
      let url = "/merapi/withdrawal/export";
      let params = {
        token,
        type: that.withdrawal_type,
        status: that.withdrawal_status,
      };

      let urlParams = urlEncode(params);
      window.location.href = `${BASE_URL2}${url}?${urlParams}`;
    },

    withdrawalAdd() {
      var data = this.amountForm;
      if (!this.withdrawalAccountList.length) {
        return app.Tips({
          title: "请先添加提现账户",
        });
      }
      if (!data.account_id) {
        return this.$message.error("请选择提现账户");
      }
      if (!data.draw_type) {
        return this.$message.error("请选择提现类型");
      }
      if (Number(data.amount) <= 0) {
        return this.$message.error("可提现金额不足");
      }
      this.$confirm("您确认提现这笔金额吗", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$http2
            .post("/merapi/withdrawal/add", data)
            .then((res) => {
              if (res.data.code == 0 || res.data.code == 200) {
                this.withdrawalVisible = false;
                location.reload();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              return this.$message.error(err);
            });
        })
        .catch(() => {});
    },
    openDialog() {
      this.withdrawalBindVisible = true;
      if (this.withdrawalAccountList.length > 0) {
        this.withdrawalAccountList.forEach((item) => {
          if (item.type == this.bindAmountForm.type) {
            this.bindAmountForm.account_name = item.account_name;
            this.bindAmountForm.account_no = item.account_no;
          }
        });
      }
    },
    typeInput() {
      if (this.amountForm.draw_type == "CE") {
        this.amountForm.amount = this.storeInfo.diff_amount;
      }
      if (this.amountForm.draw_type == "FD") {
        this.amountForm.amount = this.storeInfo.rebate_amount;
      }
      if (this.amountForm.draw_type == "BZJ") {
        this.amountForm.amount = this.storeInfo.deposit;
      }
      if (this.amountForm.draw_type == "TGJ") {
        this.amountForm.amount = this.storeInfo.item_rebate;
      }
    },
    radioInput(e) {
      if (this.withdrawalAccountList.length > 0) {
        this.withdrawalAccountList.forEach((item) => {
          if (item.type == this.bindAmountForm.type) {
            this.bindAmountForm.account_name = item.account_name;
            this.bindAmountForm.account_no = item.account_no;
          } else {
            this.bindAmountForm.account_name = "";
            this.bindAmountForm.account_no = "";
          }
        });
      }
    },
    withdrawalAccountBind() {
      let data = this.bindAmountForm;
      this.$confirm("您确认绑定这个提现账户吗？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      }).then((res) => {
        this.$http2
          .post("/merapi/withdrawal/bind", data)
          .then((res) => {
            if (res.data.code == 0 || res.data.code == 200) {
              this.withdrawalBindVisible = false;
              this.$message.success(res.data.msg);
              this.getWithdrawalAccountList();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            return this.$message.error(err);
          });
      });
    },
    getWithdrawalAccountList() {
      this.$http2
        .get("/merapi/withdrawal/account")
        .then((res) => {
          this.withdrawalAccountList = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    getShopWithdrawalList(page) {
      let start_date = "";
      let end_date = "";
      if (
        Object.prototype.toString.call(this.create_time) === "[object Array]" &&
        this.create_time[0]
      ) {
        (start_date = this.create_time[0]), (end_date = this.create_time[1]);
      }
      this.page = page || this.page;
      this.$http2
        .get("/merapi/withdrawal/list", {
          params: {
            page: this.page,
            start_date,
            type: this.withdrawal_type,
            status: this.withdrawal_status,
            end_date,
            limit: this.limit,
          },
        })
        .then((res) => {
          this.shopWithdrawalList = res.data.data.rows;
          this.shopWithdrawalTotal = res.data.data.total;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    getMessageDetail(e) {
      // 获取消息详情
      // eslint-disable-next-line camelcase
      var msg_id = e.msg_id;

      this.$http2
        .post("/merapi/message/view", { msg_id })
        .then((res) => {})
        .catch((err) => {
          return this.$message.error(err);
        });
      this.$http2
        .post("/merapi/message/detail", { msg_id })
        .then((res) => {
          this.dialogMessageVisible = true;
          this.messageDetail = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    getOrderLockInventorySettlementList(tempPage) {
      // 支付宝订单结算列表
      let remark = this.remark;
      let keyword = this.keyword;
      let create_time = "";
      let settlement_time = "";

      if (
        Object.prototype.toString.call(this.create_time) === "[object Array]" &&
        this.create_time[0]
      ) {
        create_time = formatDateArray(this.create_time[0], this.create_time[1]);
      }
      if (
        Object.prototype.toString.call(this.settlement_time) ===
          "[object Array]" &&
        this.settlement_time[0]
      ) {
        settlement_time = formatDateArray(
          this.settlement_time[0],
          this.settlement_time[1]
        );
      }
      let settlement_status = this.settlement_status;
      this.settlementPage = tempPage || this.settlementPage;
      let page = this.settlementPage;
      let limit = this.settlementLimit;
      this.$http2
        .get("/merapi/orderLockInventorySettlement/list", {
          params: {
            remark,
            keyword,
            create_time,
            settlement_time,
            page,
            limit,
            settlement_status,
          },
        })
        .then((res) => {
          this.settlementList = res.data.data.list;
          this.settlementTotal = res.data.data.total;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getSettlementDetail(data) {
      // 订单结算详情
      let settlement_no = data.settlement_no;
      this.$http2
        .get("/merapi/orderLockInventorySettlement/detailList", {
          params: {
            settlement_no,
          },
        })
        .then((res) => {
          this.detailVisible = true;
          this.settlementDetailList = res.data.data.list;
          this.tempSettlementNo = settlement_no;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    checkRules() {
      store.dispatch("setAgreementVisibleFlag2", true);
    },
    sortChange(e) {
      // 外层排序方式
      var orderBy = e.prop;
      if (e.order === "descending") {
        this.order_by = orderBy + "__desc";
      } else {
        this.order_by = orderBy + "__asc";
      }
    },

    changTabs(e) {
      this.currentTab = e.index;
      // 切换tab
      this.page = 1;
      this.create_time = [];
      this.selectMethodExecution();
    },
    curretnChange(e) {
      this.page = e;
      this.selectMethodExecution();
    },

    curretnSettlementChange(e) {
      this.settlementPage = e;
      this.getOrderLockInventorySettlementList();
    },

    settlementDetailExport() {
      var settlement_no = this.tempSettlementNo;
      // var status = this.statusValue
      var token = window.localStorage.getItem("token");

      try {
        let params = {
          settlement_no,
          token,
        };
        let data = urlEncode(params);
        window.location.href =
          BASE_URL2 +
          "/merapi/orderLockInventorySettlement/detailExport?" +
          data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    orderLockInventorySettlementDetailExport() {
      var token = window.localStorage.getItem("token");

      try {
        let remark = this.remark;
        let keyword = this.keyword;
        let create_time = "";
        let settlement_time = "";

        if (
          Object.prototype.toString.call(this.create_time) ===
            "[object Array]" &&
          this.create_time[0]
        ) {
          create_time = formatDateArray(
            this.create_time[0],
            this.create_time[1]
          );
        }
        if (
          Object.prototype.toString.call(this.settlement_time) ===
            "[object Array]" &&
          this.settlement_time[0]
        ) {
          settlement_time = formatDateArray(
            this.settlement_time[0],
            this.settlement_time[1]
          );
        }
        let settlement_status = this.settlement_status;
        let params = {
          remark,
          keyword,
          create_time,
          settlement_time,
          settlement_status,
          token,
        };
        let data = urlEncode(params);
        window.location.href =
          BASE_URL2 +
          "/merapi/orderLockInventorySettlement/export_details?" +
          data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    orderLockInventorySettlementExport() {
      var token = window.localStorage.getItem("token");

      try {
        let remark = this.remark;
        let keyword = this.keyword;
        let create_time = "";
        let settlement_time = "";

        if (
          Object.prototype.toString.call(this.create_time) ===
            "[object Array]" &&
          this.create_time[0]
        ) {
          create_time = formatDateArray(
            this.create_time[0],
            this.create_time[1]
          );
        }
        if (
          Object.prototype.toString.call(this.settlement_time) ===
            "[object Array]" &&
          this.settlement_time[0]
        ) {
          settlement_time = formatDateArray(
            this.settlement_time[0],
            this.settlement_time[1]
          );
        }
        let settlement_status = this.settlement_status;
        let params = {
          remark,
          keyword,
          create_time,
          settlement_time,
          settlement_status,
          token,
        };
        let data = urlEncode(params);
        window.location.href =
          BASE_URL2 + "/merapi/orderLockInventorySettlement/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    handleSizeChange(e) {
      this.limit = e;
      this.selectMethodExecution();
    },
    selectMethodExecution() {
      if (this.currentTab == 1) {
        this.getCommissionList();
      }

      if (this.currentTab == 2) {
        this.getShopWithdrawalList();
      }
    },
    handleSettlementSizeChange(e) {
      this.settlementLimit = e;
      this.getOrderLockInventorySettlementList();
    },

    setVisible(data) {
      // 修改信息组件传值
      this.dialogVisible = data;
    },
    setPasswordVisible(data) {
      // 修改密码组件传值
      this.passwordVisible = data;
    },
    getShopInfo() {
      // 获取商户信息

      this.$http2
        .post("/merapi/shop/info")

        .then((res) => {
          this.storeInfo = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding-bottom: 20px;
}

.account {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    width: 65%;
    // width: 760px;
    padding: 25px;
    box-sizing: border-box;
    height: 310px;

    .info {
      flex: 1;
      font-size: 16px;
      color: #222;
    }

    .line2 {
      display: flex;
      height: 45px;
      align-items: center;
      justify-content: space-between;

      .rules {
        color: blue;
        cursor: pointer;
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
      margin-left: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    // width: 380px;
    width: 30%;
    height: 310px;

    box-sizing: border-box;
    background-color: #fff;
    padding: 25px;
    font-size: 15px;
    color: #444;

    .title {
      height: 30px;
    }
    .box {
      height: 245px;
      overflow-y: scroll;
    }
    .item {
      height: 35px;
      line-height: 35px;
      width: 98%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item:hover {
      color: #0078fb;
      background-color: #eee;
      cursor: pointer;
    }
    i {
      font-size: 25px;
      color: #f71414;
      margin-bottom: 20px;
    }
  }

  .portrait {
    width: 140px;
    height: 140px;
    margin-right: 70px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .el-button {
    margin-left: 20px;
    background-color: #0078fb;
  }

  .msg {
    height: 250px;
    display: flex;
    flex: 1;
    // padding-right: 120px;
    flex-direction: column;
    font-size: 18px;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;

      div {
        // margin-right: 60px;
        flex: 1;

        span {
          margin-left: 10px;
          // font-size: 22px;
        }
      }
    }
  }
}

.module {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: space-between;

  .item {
    margin-top: 20px;
    width: 30%;
    height: 180px;
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(84, 95, 167, 0.1);
    font-family: Roboto;
    border: 0px solid rgba(239, 242, 245, 1);
    position: relative;
    padding: 25px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .trend {
      font-size: 12px;
      color: #75848c;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #50bda0;
        font-weight: normal;
        margin-right: 20px;
      }
    }
    .down {
      font-size: 12px;
      color: #75848c;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #d91d48;
        font-weight: normal;
        margin-right: 20px;
      }
    }

    .des {
      color: #75848c;
      font-size: 16px;
      // margin-bottom: 15px;
    }

    .dfcs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }
    }

    span {
      font-size: 22px;
      font-weight: 600;
    }

    .el-icon-warning {
      position: absolute;
      top: 25px;
      font-size: 24px;
      color: #d2d6d9;
      right: 25px;
    }
  }
}

.tabs {
  margin-top: 30px;
  border: 1px solid #eee;
  box-shadow: 0px 4px 12px 0px rgba(84, 95, 167, 0.1);
  padding-bottom: 30px;
  .el-tabs /deep/.el-tabs__item,
  .is-top {
    font-size: 18px;
    // font-weight: 600;
    height: 50px;
    line-height: 50px;
    color: #75848c;
  }
  .el-tabs /deep/.is-active {
    color: #1684fc;
  }
  .el-tab-pane {
    padding: 0 20px;

    .integral {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .select {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      span {
        font-size: 16px;
      }

      div {
        margin-right: 15px;
        font-size: 16px;
        color: #222;
        // font-weight: 600;
      }

      .input {
        display: flex;
        align-items: center;

        div {
          width: 100px;
        }

        .el-input {
          width: 200px;
        }
      }

      .el-button {
        margin-right: 30px;
        background-color: #0078fb;
      }
    }

    .table {
      margin-top: 20px;
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      margin-bottom: 20px;
      align-items: center;
    }
  }
}
.el-form .el-form-item /deep/ .el-form-item__label {
  font-size: 16px;
  color: #222;
}
</style>
